import { LoginForm } from "components/forms";
import { ReactComponent as LogoSymbol } from "assets/icons/logo-symbol.svg";

export const Login = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="w-11/12 sm:max-w-sm text-center">
      <LogoSymbol className="mx-auto my-8 fill-blue-500" />
      <span className="text-mg inline-block uppercase text-blue-500 bg-blue-500/20 px-3 py-1 mb-10 select-none rounded-md">
        Admin
      </span>
      <LoginForm />
    </div>
  </div>
);
