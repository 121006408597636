import { useState } from "react";
import { UsersRow } from "components/users";
import useDebounce from "hooks/useDebounce";
import { UserInterface } from "interfaces/user";
import { useUsers } from "queries/users";
import config from "utils/config";
import { strSpaceCombine } from "utils/helpers";
import { ReactComponent as SortArrowIcon } from "assets/icons/sort-arrow-icon.svg";

export const Users = () => {
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState("25");
  const debouncedSearch = useDebounce<string>(search, config.debounce.search);
  const { data, isLoading, isFetching, isRefetching } = useUsers(
    debouncedSearch,
    sortBy,
    order,
    page,
    perPage
  );

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleSort = (column: string) => {
    if (column !== sortBy) {
      setSortBy(column);
      setOrder("desc");
    } else if (column === sortBy && order === "asc") {
      setSortBy("");
      setOrder("");
    } else if (column === sortBy && order === "desc") {
      setOrder("asc");
    }
    setPage(1);
  };

  const handlePerPage = (e: any) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const renderUsersRow = data?.data?.data.length ? (
    data?.data?.data.map((user: UserInterface, index: number) => (
      <UsersRow
        key={user.uuid}
        {...user}
        search={search}
        sortBy={sortBy}
        order={order}
        page={data?.data?.meta?.current_page}
        perPage={perPage}
        index={index + 1}
      />
    ))
  ) : (
    <tr>
      <td colSpan={4} className="text-center">
        <span className="text-white/50">No results found</span>
      </td>
    </tr>
  );

  return (
    <div className="flex flex-col mx-auto px-4 pt-4 items-center max-w-[2140px] h-full">
      <div className="relative w-full">
        <input
          className="w-full h-10 px-4 rounded-md bg-white/5 hover:bg-white/10 focus:bg-white/10 placeholder:text-white/30 outline-none transition-all"
          placeholder="Search"
          onChange={handleSearch}
          value={search}
        />
        {(isLoading || isRefetching) && (
          <span className="absolute right-4 top-4 w-2 h-2 ring-1 ring-blue-500 rounded-full animate-ping z-10" />
        )}
      </div>
      <div style={{ scrollbarGutter: "stable" }} className="overflow-y-auto w-full flex-grow pb-4">
        <table className="table-auto self-stretch w-full">
          <thead className="text-left sticky top-0 z-10 bg-gradient-to-b from-[#121212]">
            <tr>
              <th
                className="pl-2 py-3 w-1/4 cursor-pointer"
                onClick={() => handleSort("first_name")}
              >
                <div className="relative flex justify-between items-center">
                  <span className="text-xs sm:text-base">Name</span>
                  {!!order && sortBy === "first_name" && (
                    <span
                      className={strSpaceCombine([
                        "px-4 absolute right-0",
                        isRefetching && "animate-pulse",
                      ])}
                    >
                      <SortArrowIcon
                        className={strSpaceCombine([
                          order === "asc" && "rotate-180",
                          "transition-all duration-300",
                        ])}
                      />
                    </span>
                  )}
                </div>
              </th>
              <th className="w-2/4 cursor-pointer peer" onClick={() => handleSort("email")}>
                <div className="relative flex justify-between items-center">
                  <span className="text-xs sm:text-base">Email</span>
                  {!!order && sortBy === "email" && (
                    <span
                      className={strSpaceCombine([
                        "px-4 absolute right-0",
                        isRefetching && "animate-pulse",
                      ])}
                    >
                      <SortArrowIcon
                        className={strSpaceCombine([
                          order === "asc" && "rotate-180",
                          "transition-all duration-300",
                        ])}
                      />
                    </span>
                  )}
                </div>
              </th>
              <th className="text-xs sm:text-base">Registered</th>
              <th className="cursor-pointer" onClick={() => handleSort("status")}>
                <div className="relative flex justify-between items-center">
                  <span className="text-xs sm:text-base">Verified</span>
                  {!!order && sortBy === "status" && (
                    <span
                      className={strSpaceCombine([
                        "px-4 absolute right-0",
                        isRefetching && "animate-pulse",
                      ])}
                    >
                      <SortArrowIcon
                        className={strSpaceCombine([
                          order === "asc" && "rotate-180",
                          "transition-all duration-300",
                        ])}
                      />
                    </span>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <>
                <tr className="bg-transparent transition-all">
                  <td className="py-2 pl-2">
                    <span className="block w-1/2 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2">
                    <span className="block w-1/2 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2">
                    <span className="block w-1/2 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2 pr-2">
                    <span className="block w-10 h-6 bg-white/5 rounded-full animate-pulse"></span>
                  </td>
                </tr>
              </>
            ) : (
              renderUsersRow
            )}
          </tbody>
        </table>
      </div>
      <div className="relative flex justify-between items-center w-full mb-4 py-2 rounded-md">
        {isLoading ? (
          <>
            <div className="w-36 h-4 rounded-md bg-white/5 animate-pulse"></div>
            <div className="flex items-center">
              <div className="w-[136px] md:w-60 h-10 rounded-md bg-white/5 animate-pulse"></div>
              <div className="ml-4 w-[71px] h-10 rounded-md bg-white/5 hidden md:block"></div>
            </div>
          </>
        ) : (
          <>
            <div className="absolute left-0 right-2 -top-6 h-6 bg-gradient-to-b from-[transparent] to-[#121212]"></div>
            <div className="text-xs ml-4">
              <span className="font-bold">{data?.data?.meta?.total}</span>
              <span className="text-white/50"> registered clinicians</span>
            </div>
            <div className="flex items-center">
              <div>
                <button
                  disabled={isFetching || data?.data?.meta?.current_page === 1}
                  className={strSpaceCombine([
                    "mr-1 w-10 h-10 transition-all rounded-md duration-300 outline-blue-500",
                    isFetching || data?.data?.meta?.current_page === 1
                      ? "text-white/20 bg-white/0"
                      : "text-white bg-white/5 hover:bg-white/10",
                  ])}
                  onClick={() => setPage(data?.data?.meta?.current_page - 1)}
                >
                  {"<"}
                </button>
                {Array(data?.data?.meta?.last_page)
                  .fill("")
                  .map((_, i) => (
                    <button
                      disabled={data?.data?.meta?.current_page === i + 1}
                      key={i}
                      className={strSpaceCombine([
                        "mx-1 w-10 h-10 transition-all rounded-md duration-300 outline-blue-500 md:inline",
                        isFetching || data?.data?.meta?.current_page === i + 1
                          ? "text-white/20 bg-white/0"
                          : "text-white bg-white/5 hover:bg-white/10",
                        data?.data?.meta?.current_page !== i + 1 && "hidden",
                      ])}
                      onClick={() => setPage(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ))}
                <button
                  disabled={
                    isFetching || data?.data?.meta?.current_page === data?.data?.meta?.last_page
                  }
                  className={strSpaceCombine([
                    "ml-1 w-10 h-10 transition-all rounded-md duration-300 outline-blue-500",
                    isFetching || data?.data?.meta?.current_page === data?.data?.meta?.last_page
                      ? "text-white/20 bg-white/0"
                      : "text-white bg-white/5 hover:bg-white/10",
                  ])}
                  onClick={() => setPage(data?.data?.meta?.current_page + 1)}
                >
                  {">"}
                </button>
              </div>
              <select
                className="ml-4 bg-white/5 px-3 h-10 rounded-md outline-blue-500 hidden md:block"
                onChange={handlePerPage}
                defaultValue="25"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
