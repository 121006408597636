import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "components/buttons";
import { Input, PasswordInput } from "components/inputs";
import { LoginInterface } from "interfaces/login";
import { useLogin } from "queries/user";

export const LoginForm = () => {
  const { mutate: login, isLoading } = useLogin();

  const handleSubmit = (data: LoginInterface) => login(data);

  return (
    <Formik initialValues={defaultValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
      {({ errors, touched, handleBlur, handleChange }) => (
        <Form className="flex flex-col">
          <Input
            containerClassName="mb-4"
            type="email"
            name="email"
            placeholder="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            errors={errors}
            disabled={isLoading}
          />
          <PasswordInput
            containerClassName="mb-4"
            type="password"
            name="password"
            placeholder="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            errors={errors}
            className="border"
            disabled={isLoading}
          />
          <Button type="submit" label="Login" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const defaultValues = {
  email: "",
  password: "",
} as LoginInterface;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .max(128, "Too long (max: 128)")
    .required("Required"),
  password: Yup.string()
    .min(8, "Too short (min: 8)")
    .max(128, "Too long (max: 128)")
    .required("Required"),
});
