import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSearchResources } from "queries/resources";

export const Resources = () => {
  const [search, setSearch] = useState("");
  const { isInitialLoading, isRefetching, refetch } = useSearchResources(search);

  const handleSearch = (e: any) => setSearch(e.target.value);

  const handleSubmit = (e: any) => {
    if (e.key === "Enter" && !!search) refetch();
  };

  return (
    <div className="flex flex-col mx-auto px-4 pt-4 items-center max-w-[2140px] h-full">
      <div className="flex flex-col w-full h-full justify-between items-center">
        <div className="sm:max-w-sm w-full my-auto">
          <h1 className="text-3xl text-center my-8">Resource Manager</h1>
          <div className="relative w-full">
            <input
              className="w-full h-10 px-4 rounded-md bg-white/5 hover:bg-white/10 focus:bg-white/10 placeholder:text-white/30 outline-none transition-all"
              placeholder="Search by Resource UUID"
              onChange={handleSearch}
              onKeyUp={handleSubmit}
            />
            {isInitialLoading || isRefetching ? (
              <span className="absolute right-4 top-4 w-2 h-2 ring-1 ring-blue-500 rounded-full animate-ping z-10" />
            ) : (
              <span className="absolute right-1 p-2 font-bold border-white/20 rounded-sm z-10">
                ⏎
              </span>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
