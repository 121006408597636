import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "assets/icons/home-icon.svg";

export const NotFound = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="text-center py-10">
      <h1 className="text-5xl mb-4">404</h1>
      <p className="text-xl text-white/30">Sorry, page couldn't be found</p>
      <footer className="flex justify-center">
        <Link
          to={"/"}
          className="flex justify-center self-center items-center p-3 mt-12 hover:text-blue-500 hover:border-blue-500 transition-all group border rounded-full"
        >
          <HomeIcon className="fill-white group-hover:fill-blue-500 transition-all" />
        </Link>
      </footer>
    </div>
  </div>
);
