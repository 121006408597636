import { UserUpdateStatusInterface } from "interfaces/user";
import { request } from "./http";

const UsersService = {
  get: (keyword?: string, sortBy?: string, order?: string, page?: number, perPage?: string) =>
    request({
      method: "get",
      url: `users?keyword=${keyword || ""}&sort_by=${sortBy || ""}&order=${order || ""}&page=${
        page || "1"
      }&per_page=${perPage || "25"}`,
    }),
  updateStatus: (data: UserUpdateStatusInterface) =>
    request({ method: "put", url: `users/${data.uuid}/status`, data }),
};

export default UsersService;
