import { Link, useRouteError } from "react-router-dom";
import { ReactComponent as HomeIcon } from "assets/icons/home-icon.svg";

export const SomethingWentWrong = () => {
  const error = useRouteError() as {
    status: number;
    statusText: string;
    data: any;
    message: string;
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center py-10">
        <h1 className="text-5xl mb-4">Oops!</h1>
        <p className="text-xl text-white/30">Sorry, an unexpected error has occurred</p>
        <p className="inline-block px-4 py-3 mt-6 bg-red-500/20 text-red-500 rounded-md">
          <i>{error.statusText || error.message}</i>
        </p>
        <footer className="flex justify-center">
          <Link
            to={"/"}
            className="flex justify-center self-center items-center p-3 mt-12 hover:text-blue-500 hover:border-blue-500 transition-all group border rounded-full"
          >
            <HomeIcon className="fill-white group-hover:fill-blue-500 transition-all" />
          </Link>
        </footer>
      </div>
    </div>
  );
};
