import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import useBoolean from "hooks/useBoolean";
import useDebounce from "hooks/useDebounce";
import { getResoureFileTypeName, strSpaceCombine } from "utils/helpers";
import { ResourceFileInterface } from "interfaces/resource";
import {
  useResourceFileRemove,
  useResourceFileUpdateFilename,
  useResourceFileUpdateStatus,
} from "queries/resources";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-icon.svg";
import { ReactComponent as ApproveIcon } from "assets/icons/approve-icon.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-icon.svg";

export const ResourceFilesRow = (
  data: ResourceFileInterface & {
    index: number;
    key: string;
    resourceUUID: string;
    filterKeyword: string;
    filterType?: string;
  }
) => {
  const { value: visible, setTrue: setVisible } = useBoolean(false);
  const { value: editable, setTrue: setEditable, setFalse: setNotEditable } = useBoolean(false);
  const [updatedFilename, setUpdatedFilename] = useState(data.filename);
  const debouncedVisibility = useDebounce<Boolean>(visible, data.index * 15);

  const { mutate: updateFilename, isLoading: isRenaming } = useResourceFileUpdateFilename(
    data.resourceUUID,
    data.filterKeyword,
    data.filterType
  );
  const { mutate: updateStatus, isLoading } = useResourceFileUpdateStatus(
    data.resourceUUID,
    data.filterKeyword,
    data.filterType
  );
  const { mutate: removeFile } = useResourceFileRemove(
    data.resourceUUID,
    data.filterKeyword,
    data.filterType
  );

  const handleUpdateFilename = () => {
    updateFilename({
      resource_uuid: data.resourceUUID,
      resource_file_uuid: data.uuid,
      filename: updatedFilename,
    });
    handleCancelRename();
  };

  const handleUpdateStatus = () =>
    updateStatus({
      resource_uuid: data.resourceUUID,
      resource_file_uuid: data.uuid,
      disabled: Boolean(data.disabled) ? 0 : 1,
    });

  const handleUpdateStatusViaKeyboard = (e: KeyboardEvent) =>
    e.code === "Enter" && handleUpdateStatus();

  const handleCancelRename = useCallback(() => {
    setUpdatedFilename(data.filename);
    setNotEditable();
  }, [data.filename, setNotEditable]);

  const handleCancelRenameViaKeyboard = (e: KeyboardEvent) => {
    switch (e.code) {
      case "Escape":
        handleCancelRename();
        break;
      case "Enter":
        handleUpdateFilename();
        break;
      default:
    }
  };

  useEffect(() => setVisible(), [setVisible]);
  useEffect(() => {
    if (!isRenaming) {
      handleCancelRename();
    }
  }, [isRenaming, handleCancelRename]);

  return (
    <tr
      className={strSpaceCombine([
        "group hover:bg-gradient-to-r from-white/5 via-white/5 bg-transparent transition-all duration-500",
        !debouncedVisibility ? "opacity-0" : "opacity-100",
      ])}
    >
      {!editable ? (
        <td
          className={strSpaceCombine([
            "text-white pl-2 py-2 w-1/3",
            isRenaming ? "animate-pulse" : "",
          ])}
          onClick={setEditable}
        >
          {data.filename}
        </td>
      ) : (
        <td className="text-white pl-2 py-2 w-1/3 flex">
          <input
            className="bg-transparent outline-none rounded-sm outline-2 outline-blue-500 mr-1"
            value={updatedFilename}
            onChange={(e) => setUpdatedFilename(e.target.value)}
            autoFocus
            onKeyDown={handleCancelRenameViaKeyboard}
          />
          <ApproveIcon
            className="mx-1 cursor-pointer flex-shrink-0 fill-white/50 hover:fill-green-500 transition-all"
            onClick={handleUpdateFilename}
          />
          <CancelIcon
            className="cursor-pointer flex-shrink-0 fill-white/50 hover:fill-red-500 transition-all"
            onClick={handleCancelRename}
          />
        </td>
      )}
      <td className="w-1/2 text-white/50">{data.uuid}</td>
      <td className="w-1/3">
        <a
          href={data.path}
          target="_blank noreferrer"
          className="text-white/50 hover:ring-2 ring-white/20 group-hover:text-white hover:text-blue-500 bg-white/5 px-2 py-1 rounded-md font-bold text-xs transition-all select-none outline-blue-500"
          title="Click to Download file"
        >
          {getResoureFileTypeName(data.type)}
        </a>
      </td>
      <td>
        <label
          tabIndex={0}
          onClick={handleUpdateStatus}
          onKeyUp={handleUpdateStatusViaKeyboard}
          className={strSpaceCombine([
            "relative cursor-pointer flex items-center w-10 h-6 rounded-full bg-white/5 hover:bg-white/20 transition-all",
            isLoading ? "animate-pulse outline-white/50" : "outline-blue-500",
          ])}
        >
          <span
            className={strSpaceCombine([
              "absolute block rounded-full transition-all",
              isLoading
                ? "w-8 h-4 bg-white/50 left-1"
                : Boolean(!data.disabled)
                ? "w-4 h-4 bg-blue-500 left-5"
                : "w-4 h-4 bg-white/50 left-1",
            ])}
          ></span>
        </label>
      </td>
      <td className="opacity-0 group-hover:opacity-100 transition-all float-right pr-3">
        <DeleteIcon
          className="cursor-pointer fill-white/50 hover:fill-red-500 transition-all"
          onClick={() => removeFile({ resource_file_uuid: data.uuid, uuid: data.resourceUUID })}
        />
      </td>
    </tr>
  );
};
