import { useMutation, useQuery } from "@tanstack/react-query";
import AuthService from "services/auth";
import { LoginInterface } from "interfaces/login";
import { clearStorage, setToStorage } from "utils/local-storage";
import { UserInterface } from "interfaces/user";
import { queryClient } from "index";
import { globalNavigate } from "components/global-history";
import { toast } from "react-toastify";

export const USER_QK = "user";

export const useLogin = () =>
  useMutation([USER_QK], (data: LoginInterface) => AuthService.login(data), {
    onSuccess: ({ data: { token } }) => {
      setToStorage("token", token);
      globalNavigate("/");
    },
  });

export const useLogout = () =>
  useMutation([USER_QK], AuthService.logout, {
    onSuccess: () => {
      clearStorage();
      globalNavigate("/login");
    },
  });

export const useProfile = () => useQuery([USER_QK], AuthService.profile);

export const useUpdateProfile = () =>
  useMutation([USER_QK], (data: UserInterface) => AuthService.updateProfile(data), {
    onSuccess: ({ data: updatedData }) => {
      queryClient.setQueryData([USER_QK], (oldData: any) => ({ ...oldData, data: updatedData }));
      toast("Profile Updated", { type: "success" });
    },
  });
