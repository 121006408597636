import { request } from "./http";
import { LoginInterface } from "interfaces/login";
import { UserInterface } from "interfaces/user";

const AuthService = {
  login: (data: LoginInterface) => request({ method: "post", url: "login", data }),
  logout: () => request({ method: "post", url: "logout" }),
  profile: () => request({ method: "get", url: "profile" }),
  updateProfile: (data: UserInterface) => request({ method: "put", url: "profile", data }),
};

export default AuthService;
