import { KeyboardEvent, MouseEvent, useEffect } from "react";
import { toast } from "react-toastify";
import useBoolean from "hooks/useBoolean";
import useDebounce from "hooks/useDebounce";
import { UserInterface, UserUpdateStatusInterface } from "interfaces/user";
import { useUsersUpdateStatus } from "queries/users";
import { getLocaleDateTime, strSpaceCombine } from "utils/helpers";

export const UsersRow = (
  data: UserInterface & {
    search: string;
    sortBy: string;
    order: string;
    page: number;
    perPage: string;
    index: number;
  }
) => {
  const { value: visible, setTrue: setVisible } = useBoolean(false);
  const debouncedVisibility = useDebounce<Boolean>(visible, data.index * 15);
  const { mutate: updateStatus, isLoading } = useUsersUpdateStatus(
    data.search,
    data.sortBy,
    data.order,
    data.page,
    data.perPage
  );

  const handleUpdateStatus = () =>
    updateStatus({
      uuid: data.uuid,
      status: Boolean(data.status) ? 0 : 1,
    } as UserUpdateStatusInterface);

  const handleUpdateStatusViaKeyboard = (e: KeyboardEvent) =>
    e.code === "Enter" && handleUpdateStatus();

  const handleCopyEmailToClipboard = (e: MouseEvent<HTMLElement>) => {
    navigator.clipboard.writeText((e.target as HTMLElement).innerText);
    toast("Email copied!", { type: "success", autoClose: 1300 });
  };

  useEffect(() => setVisible(), [setVisible]);

  return (
    <tr
      className={strSpaceCombine([
        "group hover:bg-gradient-to-r from-white/5 via-white/5 bg-transparent transition-all duration-500",
        !debouncedVisibility ? "opacity-0" : "opacity-100",
      ])}
    >
      <td className="py-2 pl-2">{strSpaceCombine([data.first_name, data.last_name])}</td>
      <td>
        <span
          className="cursor-copy text-white/50 group-hover:text-white hover:text-blue-500 hover:ring-2 ring-white/20 bg-white/5 px-2 py-1 rounded-md font-bold text-xs transition-all"
          title="Click to copy Email to clipboard"
          onClick={handleCopyEmailToClipboard}
        >
          {data.email}
        </span>
      </td>
      <td className="text-white/50" title={data.created_at}>
        {getLocaleDateTime(data.created_at).date}
      </td>
      <td>
        <label
          tabIndex={0}
          onClick={handleUpdateStatus}
          onKeyUp={handleUpdateStatusViaKeyboard}
          className={strSpaceCombine([
            "relative cursor-pointer flex items-center w-10 h-6 rounded-full bg-white/5 hover:bg-white/20 transition-all",
            isLoading ? "animate-pulse outline-white/50" : "outline-blue-500",
          ])}
        >
          <span
            className={strSpaceCombine([
              "absolute block rounded-full transition-all",
              isLoading
                ? "w-8 h-4 bg-white/50 left-1"
                : Boolean(data.status)
                ? "w-4 h-4 bg-blue-500 left-5"
                : "w-4 h-4 bg-white/50 left-1",
            ])}
          ></span>
        </label>
      </td>
    </tr>
  );
};
