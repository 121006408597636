import { Link } from "react-router-dom";
import { usePerformanceTests } from "queries/performance-tests";
import {
  getFormattedAnalysisTime,
  getFormattedCaseSize,
  getNumberOfFailedAnalyses,
  strSpaceCombine,
} from "utils/helpers";

export const PerformanceTests = () => {
  const {
    isInitialLoading: isInitialLoadingTests,
    isRefetching: isRefetchingTests,
    data: tests,
  } = usePerformanceTests();

  const renderNumberOfFailedAnalyses = (failed: number) =>
    failed ? <span className="text-xs text-red-500">{failed} failed</span> : null;

  return (
    <div className="flex flex-col mx-auto px-4 pt-4 items-center max-w-[2140px] h-full">
      <div className="flex flex-col w-full h-full justify-between items-center">
        <div className="w-full h-full overflow-y-auto">
          <div className="flex items-center justify-end mb-4">
            <span className="text-xs p-1">Average Analysis Time (mins): </span>
            <span className="text-xs p-1 mx-1 text-white bg-white/10 rounded-sm">N/A</span>
            <span className="text-xs p-1 mx-1 text-green-500 bg-green-500/10 rounded-sm">
              {"<"}3
            </span>
            <span className="text-xs p-1 mx-1 text-orange-500 bg-orange-500/10 rounded-sm">
              3-5
            </span>
            <span className="text-xs p-1 mx-1 text-red-500 bg-red-500/10 rounded-sm">5+</span>
          </div>
          <div className="relative flex flex-wrap">
            {isInitialLoadingTests || isRefetchingTests ? (
              <span className="absolute right-4 top-4 w-2 h-2 ring-1 ring-blue-500 rounded-full animate-ping z-10" />
            ) : (
              tests &&
              tests.data.map((test: any) => (
                <Link
                  to={"/performance-tests/" + test.id}
                  key={test.id}
                  className="relative flex justify-center self-start flex-grow basis-1/6 flex-shrink-0 min-w-[160px] min-h-[120px] m-1 bg-white/10 rounded-md hover:bg-white/20 transition-colors cursor-pointer"
                >
                  <div className="absolute left-2 top-0 opacity-50">
                    <span className="text-xs">#{test.id}</span>
                  </div>
                  <div className="absolute right-2 top-0">
                    <span
                      className={strSpaceCombine([
                        "text-xs",
                        test.avg_time > 300
                          ? "text-red-500"
                          : test.avg_time > 180
                          ? "text-orange-500"
                          : test.avg_time > 0 && test.avg_time <= 180
                          ? "text-green-500"
                          : "opacity-50",
                      ])}
                    >
                      avg.{" "}
                      {getFormattedAnalysisTime(test.avg_time) +
                        " • " +
                        getFormattedCaseSize(test.avg_size)}
                    </span>
                  </div>
                  <div className="absolute mx-auto bottom-1 opacity-50">
                    {renderNumberOfFailedAnalyses(
                      getNumberOfFailedAnalyses(test.performance_analyses)
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center  p-2 my-1">
                    <span className="text-xl font-bold">{test.performance_analyses.length}</span>
                    <span className="text-xs">
                      {test.performance_analyses.length > 1 ? "Analyses" : "Analysis"}
                    </span>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
