export const strSpaceCombine = (array: Array<any>) => array.filter(Boolean).join(" ");

export const getLocaleDateTime = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date();

  return {
    date: dateObj.toLocaleDateString(),
    time: dateObj.toLocaleTimeString(),
  };
};

export const getResoureFileTypeName = (type: string | number) => {
  switch (type.toString()) {
    case "1":
      return "IMG";
    case "2":
      return "DCM";
    case "3":
      return "NRRD";
    case "4":
      return "STL";
    case "5":
      return "ZIP";
    case "6":
      return "NII_GZ";
    case "7":
      return "STL_IOS";
    default:
      return "unknown";
  }
};

export const getAnalysisStatus = (type: string | number) => {
  switch (type.toString()) {
    case "0":
      return "CREATED";
    case "1":
      return "IN PROGRESS";
    case "2":
      return "DONE";
    case "3":
      return "FAILED";
    default:
      return "unknown";
  }
};

export const getAnalysisStatusColour = (type: string | number) => {
  switch (type.toString()) {
    case "0":
      return "text-white";
    case "1":
      return "text-blue-500";
    case "2":
      return "text-green-500";
    case "3":
      return "text-red-500";
    default:
      return "text-white/50";
  }
};

export const getFormattedAnalysisTime = (time: number) =>
  time > 0 ? ~~(time / 60) + "m " + Math.round(time % 60) + "s" : "N/A";

export const getMinMaxAnalysisTime = (analysis: Array<any>) => {
  const times = analysis.map((analysis) => analysis.time);

  return [Math.min(...times), Math.max(...times)];
};

export const getFormattedCaseSize = (size: number) =>
  size !== 0 ? Math.round(size) + "MB" : "N/A";

export const getMinMaxCaseSize = (analysis: Array<any>) => {
  const sizes = analysis.map((analysis) => analysis.size);

  return [Math.min(...sizes), Math.max(...sizes)];
};

export const getNumberOfFailedAnalyses = (performanceAnalyses: Array<any>) => {
  let failed = 0;
  performanceAnalyses.forEach((analysis) => (analysis.status === 3 ? ++failed : false));

  return failed;
};
