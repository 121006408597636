import { useMutation, useQuery } from "@tanstack/react-query";
import ResourcesService from "services/resources";
import { globalNavigate } from "components/global-history";
import {
  ResourceFileInterface,
  ResourceFilesUpdateStatusByTypeInterface,
  ResourceFileUpdateStatusInterface,
  ResourceFileUploadInterface,
  ResourceInterface,
  ResourceRemoveFileInterface,
  ResourceRemoveFilesByTypeInterface,
} from "interfaces/resource";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { getResoureFileTypeName } from "utils/helpers";
import { ResourceFileUpdateFilenameInterface } from "../interfaces/resource";

export const RESOURCES_QK = "resources";
export const RESOURCE_QK = "resource";
export const RESOURCE_FILE_QK = "resource-file";

export const useSearchResources = (uuid?: string) =>
  useQuery([RESOURCES_QK, uuid], () => ResourcesService.search(uuid), {
    onSuccess: ({ data: resources }: { data: Array<ResourceInterface> }) => {
      if (resources.length === 1) {
        globalNavigate(`/resources/${resources[0].uuid}`);
      } else {
        toast("Resource Not Found", { toastId: "resource-not-found", type: "warning" });
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

export const useResource = (uuid?: string) =>
  useQuery([RESOURCE_QK, uuid], () => ResourcesService.get(uuid), {
    onError: () => {
      globalNavigate("/resources");
      toast("Resource Not Found", { toastId: "resource-not-found", type: "warning" });
    },
    retry: false,
  });

export const useResourceFiles = (uuid?: string, keyword?: string, type?: string) =>
  useQuery(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    () => ResourcesService.getResourceFiles(uuid, keyword, type),
    {
      retry: false,
    }
  );

export const useResourceRemoveFilesByType = (uuid?: string) =>
  useMutation(
    [RESOURCE_QK, uuid],
    (data: ResourceRemoveFilesByTypeInterface) => ResourcesService.removeFilesByType(data),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([RESOURCES_QK]);
        queryClient.invalidateQueries([RESOURCE_QK]);
        queryClient.invalidateQueries([RESOURCE_FILE_QK]);
        toast(`${getResoureFileTypeName(variables.type)} files removed`, { type: "success" });
      },
      retry: false,
    }
  );

export const useResourceFileUpdateFilename = (uuid?: string, keyword?: string, type?: string) =>
  useMutation(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    (data: ResourceFileUpdateFilenameInterface) => ResourcesService.updateFilename(data),
    {
      onSuccess: (updatedData, variables) => {
        queryClient.setQueryData([RESOURCE_FILE_QK, uuid, keyword, type], (oldData: any) => {
          const oldResourceFiles = oldData.data;
          const updatedResourceFile: ResourceFileInterface = updatedData.data;
          return {
            ...oldData,
            data: oldResourceFiles.map((resourceFile: ResourceFileInterface) =>
              resourceFile.uuid === variables.resource_file_uuid
                ? { ...resourceFile, filename: updatedResourceFile.filename }
                : resourceFile
            ),
          };
        });
        toast(`File renamed to "${variables.filename}"`, { type: "success" });
      },
    }
  );

export const useResourceFileUpdateStatus = (uuid?: string, keyword?: string, type?: string) =>
  useMutation(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    (data: ResourceFileUpdateStatusInterface) => ResourcesService.updateStatus(data),
    {
      onSuccess: (updatedData, variables) => {
        queryClient.setQueryData([RESOURCE_FILE_QK, uuid, keyword, type], (oldData: any) => {
          const oldResourceFiles = oldData.data;
          const updatedResourceFile: ResourceFileInterface = updatedData.data;
          return {
            ...oldData,
            data: oldResourceFiles.map((resourceFile: ResourceFileInterface) =>
              resourceFile.uuid === variables.resource_file_uuid
                ? { ...resourceFile, disabled: updatedResourceFile.disabled }
                : resourceFile
            ),
          };
        });
      },
    }
  );

export const useResourceFilesUpdateStatusByType = (
  uuid?: string,
  keyword?: string,
  type?: string
) =>
  useMutation(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    (data: ResourceFilesUpdateStatusByTypeInterface) => ResourcesService.updateStatusByType(data),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData([RESOURCE_FILE_QK, uuid, keyword, type], (oldData: any) => {
          const oldResourceFiles = oldData.data;
          return {
            ...oldData,
            data: oldResourceFiles.map((resourceFile: ResourceFileInterface) =>
              resourceFile.type === variables.type
                ? { ...resourceFile, disabled: variables.disabled }
                : resourceFile
            ),
          };
        });
        toast(
          `${getResoureFileTypeName(variables.type)} files are ${
            variables.disabled ? "hidden" : "now visible"
          }`,
          { type: "success" }
        );
      },
    }
  );

export const useResourceFileRemove = (uuid?: string, keyword?: string, type?: string) =>
  useMutation(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    (data: ResourceRemoveFileInterface) => ResourcesService.removeFile(data),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData([RESOURCE_FILE_QK, uuid, keyword, type], (oldData: any) => {
          const oldResourceFiles = oldData.data;
          return {
            ...oldData,
            data: oldResourceFiles.filter((resourceFile: ResourceFileInterface) =>
              resourceFile.uuid === variables.resource_file_uuid ? false : true
            ),
          };
        });
        toast(`File removed`, { type: "success" });
      },
    }
  );

export const useResourceFileUpload = (uuid?: string, keyword?: string, type?: string) =>
  useMutation(
    [RESOURCE_FILE_QK, uuid, keyword, type],
    (data: ResourceFileUploadInterface) => ResourcesService.uploadFile(data),
    {
      onSuccess: (_, variables) => {
        toast(`${variables.file.name} uploaded`, { type: "success" });
        queryClient.invalidateQueries([RESOURCE_FILE_QK]);
      },
    }
  );
