import { NavLink, Outlet } from "react-router-dom";
import { ProfileCard } from "components/user";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { strSpaceCombine } from "utils/helpers";

export const App = () => {
  const getNavLinkStyle = ({ isActive }: { isActive: boolean }) =>
    strSpaceCombine([
      isActive ? "text-white" : "text-white/40 hover:text-white",
      "mr-6 hidden sm:inline-block transition-all",
    ]);

  return (
    <div className="h-screen">
      <header className="flex flex-wrap justify-between items-center px-4 py-3">
        <NavLink to="/" className="outline-blue-500">
          <Logo className="flex-shrink-" />
        </NavLink>
        <div className="flex-grow ml-8">
          <NavLink to="/" end className={getNavLinkStyle}>
            Users
          </NavLink>
          <NavLink to="/resources" end className={getNavLinkStyle}>
            Resource Manager
          </NavLink>
          <NavLink to="/performance-tests" end className={getNavLinkStyle}>
            Performance Tests
          </NavLink>
        </div>
        <ProfileCard />
      </header>
      <main style={{ height: "calc(100vh - 66px)" }}>
        <Outlet />
      </main>
    </div>
  );
};
