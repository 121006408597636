export const getFromStorage = (key: string) => {
  const payload = localStorage.getItem(key);

  if (!!payload) {
    return JSON.parse(payload);
  }

  return null;
};

export const setToStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // TODO: throw error
  }
};

export const removeFromStorage = (key: string) => localStorage.removeItem(key);

export const clearStorage = () => localStorage.clear();
