import { FC } from "react";
import { strSpaceCombine } from "utils/helpers";
import { InputProps } from "./input-props.interface";

export const Input: FC<InputProps> = ({
  type = "text",
  name,
  className,
  containerClassName,
  touched,
  errors,
  onBlur,
  onChange,
  ...props
}) => {
  return (
    <div className={strSpaceCombine(["flex flex-col", containerClassName])}>
      <input
        className={strSpaceCombine([
          "bg-transparent border border-slate-600 hover:border-blue-500 rounded-md px-4 py-3 outline-blue-500 transition-all",
          className,
        ])}
        type={type}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
      {touched[name] && errors[name] && (
        <div className="self-start mt-2 px-2 py-1 rounded-md text-xs text-red-400 bg-red-400/20">
          {errors[name]?.toString()}
        </div>
      )}
    </div>
  );
};
