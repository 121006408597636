import { FC } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { usePerformanceTestAnalysisById } from "queries/performance-tests";

export const HorizontalTimeBreakdown: FC<{ uuid: string }> = ({ uuid }) => {
  const { data: analysis } = usePerformanceTestAnalysisById(uuid);
  return (
    <div className="group cursor-pointer flex items-center outline-blue-500 h-24">
      {analysis?.data && analysis?.data?.times && (
        <ResponsiveBar
          key={2}
          data={analysis?.data?.times}
          indexBy="name"
          keys={[
            "MandibleGroup1",
            "MandibleGroup4",
            "MaxillaGroup2",
            "MandibleGroup2",
            "MandibleGroup5",
            "MaxillaGroup3",
            "MandibleGroup3",
            "MaxillaGroup1",
            "Sinus",
          ]}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          padding={0.6}
          innerPadding={2}
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "pastel2" }}
          maxValue={300}
          borderRadius={2}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisLeft={null}
          axisRight={null}
          enableGridX={true}
          enableGridY={true}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          role="application"
          ariaLabel="AI Model Inference Time"
          barAriaLabel={function (e) {
            return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
          }}
          theme={{
            background: "transparent",
            textColor: "#ffffff",
            fontSize: 11,
            axis: {
              domain: {
                line: {
                  stroke: "#777777",
                  strokeWidth: 1,
                },
              },
              legend: {
                text: {
                  fontSize: 12,
                  fill: "#fff",
                },
              },
              ticks: {
                line: {
                  stroke: "#777777",
                  strokeWidth: 1,
                },
                text: {
                  fontSize: 11,
                  fill: "#fff",
                },
              },
            },
            grid: {
              line: {
                stroke: "#dddddd",
                strokeWidth: 1,
              },
            },
            annotations: {
              text: {
                fontSize: 13,
                fill: "#fff",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
              },
              link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
              },
              outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
              },
              symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
              },
            },
            tooltip: {
              container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12,
              },
              basic: {},
              chip: {},
              table: {},
              tableCell: {},
              tableCellValue: {},
            },
          }}
        />
      )}
    </div>
  );
};
