import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { toast } from "react-toastify";
import PerformanceTestService from "services/performance-test";

export const PERFORMANCE_TESTS_QK = "performance-tests";
export const PERFORMANCE_TEST_ANALYSIS_QK = "performance-tests/analysis";

export const usePerformanceTests = () =>
  useQuery([PERFORMANCE_TESTS_QK], () => PerformanceTestService.get(), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const usePerformanceTestById = (uuid?: string) =>
  useQuery([PERFORMANCE_TESTS_QK, uuid], () => PerformanceTestService.getById(uuid), {
    onError: () => {
      toast("Resource Not Found", { toastId: "resource-not-found", type: "warning" });
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const usePerformanceTestAnalysisById = (uuid?: string) =>
  useQuery(
    [PERFORMANCE_TEST_ANALYSIS_QK, uuid],
    () => PerformanceTestService.getAnalysisById(uuid),
    {
      onSuccess: (res) => {
        const logs = res?.data?.ai_analysis_time_logs?.workers_time_logs;
        const times = [logs[0].slice(0, 3), logs[1].slice(0, 3), logs[2].slice(0, 3)];

        const preparedData = [
          {
            name: "Worker 1",
            MandibleGroup1: times[0][0].time,
            MandibleGroup1Color: "hsl(123, 70%, 50%)",
            MandibleGroup4: times[0][1].time,
            MandibleGroup4Color: "hsl(52, 70%, 50%)",
            MaxillaGroup2: times[0][2].time,
            MaxillaGroup2Color: "hsl(87, 70%, 50%)",
          },
          {
            name: "Worker 2",
            MandibleGroup2: times[1][0].time,
            MandibleGroup2Color: "hsl(324, 70%, 50%)",
            MandibleGroup5: times[1][1].time,
            MandibleGroup5Color: "hsl(324, 70%, 50%)",
            MaxillaGroup3: times[1][2].time,
            MaxillaGroup3Color: "hsl(284, 70%, 50%)",
          },
          {
            name: "Worker 3",
            MandibleGroup3: times[2][0].time,
            MandibleGroup3Color: "hsl(100, 70%, 50%)",
            MaxillaGroup1: times[2][1].time,
            MaxillaGroup1Color: "hsl(40, 70%, 50%)",
            Sinus: times[2][2].time,
            SinusColor: "hsl(260, 70%, 50%)",
          },
        ];

        queryClient.setQueryData([PERFORMANCE_TEST_ANALYSIS_QK, uuid], {
          ...res,
          data: { ...res.data, times: preparedData },
        });
      },
      onError: () => {
        toast("Resource Not Found", { toastId: "resource-not-found", type: "warning" });
      },
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
