import { request } from "./http";

const PerformanceTestService = {
  get: () =>
    request({
      method: "get",
      url: `admin/performance-tests`,
    }),
  getById: (uuid?: string) =>
    request({
      method: "get",
      url: `admin/performance-tests/${uuid || ""}`,
    }),
  getAnalysisById: (uuid?: string) =>
    request({
      method: "get",
      url: `admin/performance-analyses/${uuid || ""}`,
    }),
};

export default PerformanceTestService;
