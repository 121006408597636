import { Button } from "components/buttons";
import { useResourceFileUpload } from "queries/resources";
import { FC, useRef, useState } from "react";
import { getResoureFileTypeName, strSpaceCombine } from "utils/helpers";
import { ReactComponent as UploadIcon } from "assets/icons/upload-icon.svg";

export const ResourceFileUploader: FC<{ uuid: string | undefined; type?: string | undefined }> = ({
  uuid,
  type,
}) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const { mutate: uploadFile, isLoading: isUploading } = useResourceFileUpload();

  const handleFileChange = (event: any) => {
    setFiles(event.target.files);
  };

  const handleUpload = (ref: any) => {
    if (!!uuid && files.length) {
      for (const id in files) {
        if (Object.prototype.hasOwnProperty.call(files, id)) {
          const file = files[id];
          uploadFile({ uuid, file, type });
        }
      }
      if (ref.current) ref.current.value = null;
    }
  };

  return (
    <div className="flex flex-col mt-2 mb-6">
      <div className="p-2 rounded-md border-2 border-white/5 flex justify-between">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          disabled={isUploading}
        />
        <Button
          containerClassName={strSpaceCombine([
            "ml-2 mt-0 py-0 h-[30px] w-16",
            files.length === 0 ? "bg-white/0 opacity-20" : "opacity-100",
          ])}
          onClick={() => handleUpload(fileInputRef)}
          disabled={isUploading || files.length === 0}
          loading={isUploading}
        >
          <UploadIcon className={strSpaceCombine(["mx-auto"])} />
        </Button>
      </div>
      <div className="mt-2 text-xs text-center">
        <span className="text-white/50">Supported formats: </span>
        {(type && type === "7"
          ? [getResoureFileTypeName(7)]
          : [
              getResoureFileTypeName(2),
              getResoureFileTypeName(3),
              getResoureFileTypeName(4),
              getResoureFileTypeName(6),
            ]
        ).map((tag) => (
          <span key={tag} className="ml-1 font-bold text-blue-500 rounded-sm px-1 bg-blue-500/10">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};
