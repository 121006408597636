import { LogoutButton } from "components/buttons";
import { UpdateProfileForm } from "components/forms";

export const Profile = () => (
  <div className="flex flex-col h-full justify-between items-center">
    <div className="w-11/12 sm:max-w-sm my-auto">
      <h1 className="text-3xl text-center my-8">Profile</h1>
      <UpdateProfileForm />
      <LogoutButton className="w-full" label="Logout" />
    </div>
  </div>
);
