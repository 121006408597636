import { useProfile } from "queries/user";
import { NavLink } from "react-router-dom";
import { strSpaceCombine } from "utils/helpers";

export const ProfileCard = () => {
  const { data, isLoading } = useProfile();

  return (
    <NavLink to={"profile"} className="group cursor-pointer flex items-center outline-blue-500">
      {isLoading ? (
        <span key={1} className="block w-24 h-4 rounded-md bg-white/5 animate-pulse mr-4"></span>
      ) : (
        <span
          key={2}
          className="text-white/40 group-hover:text-white mr-4 hidden sm:block transition-all"
        >
          {strSpaceCombine([data?.data?.first_name, data?.data?.last_name])}
        </span>
      )}
      <div className="flex justify-center items-center w-10 h-10 rounded-full bg-white/5 group-hover:bg-white/10 text-sm font-bold transition-colors">
        {isLoading ? "" : data?.data?.first_name[0] + data?.data?.last_name[0]}
      </div>
    </NavLink>
  );
};
