import {
  ResourceRemoveFilesByTypeInterface,
  ResourceFileUpdateStatusInterface,
  ResourceRemoveFileInterface,
  ResourceFilesUpdateStatusByTypeInterface,
  ResourceFileUploadInterface,
  ResourceFileUpdateFilenameInterface,
} from "interfaces/resource";
import { request } from "./http";

const ResourcesService = {
  search: (uuid?: string) =>
    request({
      method: "get",
      url: `admin/resources/search?uuid=${uuid || ""}`,
    }),
  get: (uuid?: string) =>
    request({
      method: "get",
      url: `admin/resources/${uuid || ""}`,
    }),
  getResourceFiles: (uuid?: string, keyword?: string, type?: string) =>
    request({
      method: "get",
      url: `admin/resources/${uuid || ""}/resource-files/search?keyword=${keyword || ""}&type=${
        type || ""
      }`,
    }),
  removeFilesByType: (data: ResourceRemoveFilesByTypeInterface) =>
    request({
      method: "delete",
      url: `admin/resources/${data.uuid}/resource-files/delete-by-type/${data.type}`,
    }),
  updateFilename: (data: ResourceFileUpdateFilenameInterface) =>
    request({
      method: "put",
      url: `admin/resources/${data.resource_uuid}/resource-files/set-filename/${data.resource_file_uuid}`,
      data: { filename: data.filename },
    }),
  updateStatus: (data: ResourceFileUpdateStatusInterface) =>
    request({
      method: "put",
      url: `admin/resources/${data.resource_uuid}/resource-files/set-status/${data.resource_file_uuid}`,
      data: { disabled: data.disabled },
    }),
  updateStatusByType: (data: ResourceFilesUpdateStatusByTypeInterface) =>
    request({
      method: "put",
      url: `admin/resources/${data.uuid}/resource-files/set-status-by-type/${data.type || ""}`,
      data: { disabled: data.disabled },
    }),
  removeFile: (data: ResourceRemoveFileInterface) =>
    request({
      method: "delete",
      url: `admin/resources/${data.uuid}/resource-files/${data.resource_file_uuid}`,
    }),
  uploadFile: (data: ResourceFileUploadInterface) => {
    const formData = new FormData();
    formData.append("file", data.file);
    data.type && formData.append("type", data.type);
    return request({
      method: "post",
      url: `admin/resources/${data.uuid}/resource-files`,
      data: formData,
    });
  },
  downloadFilesByType: (uuid?: string, type?: number) =>
    request({
      method: "get",
      url: `admin/resources/${uuid || ""}/resource-files/download/${type || ""}`,
      responseType: type === 5 ? "json" : "blob",
    }),
};

export default ResourcesService;
