import axios, { AxiosRequestConfig } from "axios";
import { clearStorage, getFromStorage } from "utils/local-storage";
import config from "utils/config";
import { globalNavigate } from "components/global-history";
import { queryClient } from "index";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: config.api.baseUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = getFromStorage("token");

    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      clearStorage();
      globalNavigate("/login");
      queryClient.cancelQueries();
      queryClient.removeQueries();
      console.log(error);
      if (error.response.data.message)
        toast(error.response.data.message, { toastId: "invalid-credentials", type: "warning" });
      else if (error.response.data.message === "Unauthenticated.")
        toast("Session Expired", { toastId: "session-expired", type: "warning" });
      else
        toast(JSON.stringify(error.response.data), { toastId: "unknown-error", type: "warning" });

      return Promise.reject(error);
    } else if (error?.response?.status === 422) {
      toast(JSON.stringify(error.response.data.errors), {
        toastId: "validation-error",
        type: "warning",
      });
    }
    // return Error object with Promise
    return Promise.reject(error);
  }
);

export const request = async ({ url, method, data, headers = {}, ...rest }: AxiosRequestConfig) => {
  try {
    return await api({ method, url, headers, data, ...rest });
  } catch (error) {
    const isAxiosError = axios.isAxiosError(error);

    if (isAxiosError && error.response === undefined) {
      toast("Server is not responding", { toastId: "server-not-responding", type: "error" });
    } else if (isAxiosError && error.response?.status === 500) {
      toast("Server Error. Please, reload the app", { toastId: "server-error", type: "error" });
    }

    throw error;
  }
};

export default request;
