import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "views/router";
import { GlobalHistory } from "components/global-history";
import config from "utils/config";
import { strSpaceCombine } from "utils/helpers";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalHistory />
        <AppRouter />
        <ToastContainer
          {...config.toastify}
          toastClassName={(ctx) =>
            strSpaceCombine([
              config.toastifyProgressClass[ctx?.type || "default"],
              "flex mb-1 p-2 rounded-md justify-between items-center cursor-pointer backdrop-blur-md overflow-hidden",
            ])
          }
          progressClassName={"!bg-current"}
        />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
