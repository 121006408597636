import { useParams } from "react-router-dom";
import { usePerformanceTestById } from "queries/performance-tests";
import {
  getAnalysisStatus,
  getAnalysisStatusColour,
  getFormattedAnalysisTime,
  getFormattedCaseSize,
  getMinMaxAnalysisTime,
  getMinMaxCaseSize,
  getNumberOfFailedAnalyses,
  strSpaceCombine,
} from "utils/helpers";
import { HorizontalTimeBreakdown } from "components/stats";

export const PerformanceTest = () => {
  const { uuid } = useParams();
  const {
    isInitialLoading: isInitialLoadingTest,
    isRefetching: isRefetchingTest,
    data: test,
  } = usePerformanceTestById(uuid);

  const renderAvgAnalysisTime = () => {
    const minMax = test && getMinMaxAnalysisTime(test.data.performance_analyses);

    if (!test || !minMax) return null;

    const [min, max] = minMax;

    return (
      <>
        <span className="text-xs uppercase opacity-50">Avg. Analysis Time</span>
        <div className="flex flex-col">
          <span className="text-2xl">{getFormattedAnalysisTime(test.data.avg_time)}</span>
          <span className="text-xs">
            L: {getFormattedAnalysisTime(min)} • H: {getFormattedAnalysisTime(max)}
          </span>
        </div>
      </>
    );
  };

  const renderAvgCaseSize = () => {
    const minMax = test && getMinMaxCaseSize(test.data.performance_analyses);

    if (!test || !minMax) return null;

    const [min, max] = minMax;

    return (
      <>
        <span className="text-xs uppercase opacity-50">Avg. Case Size</span>
        <div className="flex flex-col">
          <span className="text-2xl">{getFormattedCaseSize(test.data.avg_size)}</span>
          <span className="text-xs">
            L: {getFormattedCaseSize(min)} • H: {getFormattedCaseSize(max)}
          </span>
        </div>
      </>
    );
  };

  const renderNumberOfFailedAnalyses = (failed: number, total: number) =>
    failed ? (
      <span className="text-xs uppercase text-red-500">
        {failed} failed ({((failed / total) * 100).toFixed(2)}%)
      </span>
    ) : null;

  return (
    <div className="flex flex-col mx-auto px-4 pt-4 items-center max-w-[2140px] h-full">
      <div className="flex flex-col w-full h-full justify-between items-center">
        <div className="w-full h-full overflow-y-auto">
          <div className="flex items-start justify-start mb-4">
            <div className="flex flex-col items-start justify-start mb-4 mr-8">
              <span className="text-xs uppercase opacity-50">Patient</span>
              <span className="text-2xl">
                {test && test.data.performance_analyses[0].resource.patient.full_name}
              </span>
            </div>
            <div className="flex flex-col items-start justify-start mb-4 mx-8">
              <span className="text-xs uppercase opacity-50">Number of Cases</span>
              <span className="text-2xl">{test && test.data.performance_analyses.length}</span>
              {test &&
                renderNumberOfFailedAnalyses(
                  getNumberOfFailedAnalyses(test.data.performance_analyses),
                  test.data.performance_analyses.length
                )}
            </div>
            <div className="flex flex-col items-start justify-start mb-4 mx-8">
              {renderAvgAnalysisTime()}
            </div>
            <div className="flex flex-col items-start justify-start mb-4">
              {renderAvgCaseSize()}
            </div>
          </div>
          <div className="relative flex flex-wrap">
            {isInitialLoadingTest || isRefetchingTest ? (
              <span className="absolute right-4 top-4 w-2 h-2 ring-1 ring-blue-500 rounded-full animate-ping z-10" />
            ) : (
              test &&
              test.data &&
              test.data.performance_analyses &&
              test.data.performance_analyses.map((analysis: any) => (
                <div
                  key={analysis.id}
                  className={strSpaceCombine([
                    analysis.status !== 3 ? "min-h-[80px]" : "",
                    "relative flex flex-col self-start flex-grow basis-1/2 flex-shrink-0 min-w-[160px] m-1 bg-white/10 rounded-md hover:bg-white/20 transition-colors cursor-pointer",
                  ])}
                >
                  <div className="flex justify-between flex-grow px-2">
                    <div>
                      <span className="text-xs font-bold">{analysis.resource?.name}</span>
                      <span className="text-xs px-1 opacity-50">(#{analysis.id})</span>
                    </div>
                    <div>
                      <span className="text-xs px-1">{Math.round(analysis.size)}MB</span>
                      <span className="text-xs px-1">
                        {getFormattedAnalysisTime(analysis.time)}
                      </span>
                      <span
                        className={strSpaceCombine([
                          "text-xs",
                          getAnalysisStatusColour(analysis.status),
                        ])}
                      >
                        {getAnalysisStatus(analysis.status)}
                      </span>
                    </div>
                  </div>
                  {analysis.status === 2 && <HorizontalTimeBreakdown uuid={analysis.id} />}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
