import { Route, Routes } from "react-router-dom";
import { PrivateRoute, ProtectedRoute } from "components/router";
import { Login, Profile } from "views/account";
import { NotFound } from "views/not-found";
import { SomethingWentWrong } from "views/something-went-wrong";
import { App, Users } from "views/app";
import { Resources, Resource } from "views/manager";
import { PerformanceTests, PerformanceTest } from "views/performance-stats";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />} errorElement={<SomethingWentWrong />}>
        <Route path="/" element={<App />}>
          <Route index element={<Users />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/:uuid" element={<Resource />} />
          <Route path="/performance-tests" element={<PerformanceTests />} />
          <Route path="/performance-tests/:uuid" element={<PerformanceTest />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Route>
      <Route path="/" element={<ProtectedRoute />} errorElement={<SomethingWentWrong />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
