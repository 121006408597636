import { FC } from "react";
import { useLogout } from "queries/user";
import { ButtonProps } from "./button-props.interface";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-icon.svg";
import { strSpaceCombine } from "utils/helpers";

export const LogoutButton: FC<ButtonProps> = ({ className, label, ...props }) => {
  const { mutate: logout, isLoading: loading } = useLogout();

  const handleLogout = () => logout();

  return (
    <button
      type="button"
      className={strSpaceCombine([
        "bg-red-500 py-3 px-4 mt-4 rounded-md outline-none focus:ring-8 focus:ring-red-500/30 transition-all",
        `${loading && "cursor-not-allowed grayscale"}`,
        className,
      ])}
      disabled={loading}
      {...props}
      onClick={handleLogout}
    >
      {loading ? <LoaderIcon className="animate-spin mx-auto" /> : label}
    </button>
  );
};
