import { FC, useState } from "react";
import { Input } from "./input";
import { InputProps } from "./input-props.interface";
import { ReactComponent as ShowIcon } from "assets/icons/visible-icon.svg";
import { ReactComponent as HideIcon } from "assets/icons/hidden-icon.svg";
import { strSpaceCombine } from "utils/helpers";

export const PasswordInput: FC<InputProps> = ({
  type = "password",
  name,
  className,
  containerClassName,
  touched,
  errors,
  onBlur,
  onChange,
  ...props
}) => {
  const [localType, setLocalType] = useState(type);

  return (
    <div className={strSpaceCombine(["relative", containerClassName])}>
      <Input
        className={className}
        type={localType}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        errors={errors}
        touched={touched}
        {...props}
      />
      <button
        className={`absolute right-2 top-[9px] uppercase text-xs w-8 h-8 hover:bg-blue-500/20 rounded-full group outline-blue-500 transition-all ${
          localType === "password" ? "bg-transparent" : "bg-blue-500/20"
        }`}
        type="button"
        tabIndex={-1}
        onClick={() => {
          setLocalType(localType === "password" ? "text" : "password");
        }}
      >
        {localType === "password" ? (
          <HideIcon className="fill-slate-500 group-hover:fill-blue-500 mx-auto" />
        ) : (
          <ShowIcon className="fill-blue-500 mx-auto" />
        )}
      </button>
    </div>
  );
};
