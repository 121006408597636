import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "components/buttons";
import { Input } from "components/inputs";
import { useProfile, useUpdateProfile } from "queries/user";
import { UserInterface } from "interfaces/user";

export const UpdateProfileForm = () => {
  const { data, isLoading: isLoadingProfile, isSuccess: isSuccessProfile } = useProfile();
  const { mutate: updateProfile, isLoading } = useUpdateProfile();

  const handleSubmit = (data: UserInterface) => updateProfile(data);

  return (
    <Formik
      initialValues={
        isSuccessProfile
          ? { first_name: data?.data?.first_name, last_name: data?.data?.last_name }
          : defaultValues
      }
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ errors, touched, handleBlur, handleChange, values, dirty }) => (
        <Form className="flex flex-col">
          <Input
            containerClassName="mb-4"
            type="text"
            name="first_name"
            placeholder="First name"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            errors={errors}
            disabled={isLoading || isLoadingProfile}
            value={values.first_name}
          />
          <Input
            containerClassName="mb-4"
            type="text"
            name="last_name"
            placeholder="Last name"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            errors={errors}
            disabled={isLoading || isLoadingProfile}
            value={values.last_name}
          />
          <Button type="submit" label="Update Profile" loading={isLoading || isLoadingProfile} />
        </Form>
      )}
    </Formik>
  );
};

const defaultValues = {
  first_name: "",
  last_name: "",
} as UserInterface;

const LoginSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too short (min: 2)")
    .max(128, "Too long (max: 128)")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Too short (min: 2)")
    .max(128, "Too long (max:128)")
    .required("Required"),
});
