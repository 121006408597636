import { ToastOptions } from "react-toastify";

const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  toastify: {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "dark",
    progressStyle: {
      height: 2,
    },
    icon: false,
  } as ToastOptions,
  toastifyProgressClass: {
    success: "bg-green-500/20 text-green-500",
    error: "bg-red-500/20 text-red-500",
    info: "bg-black/20",
    warning: "bg-orange-500/20 text-orange-500",
    default: "bg-blue-500/20  text-blue-500",
    dark: "bg-white-600/20 font-gray-300",
  },
  debounce: {
    search: 350,
  },
};

export default config;
