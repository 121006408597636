import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { UserInterface, UserUpdateStatusInterface } from "interfaces/user";
import UsersService from "services/users";

export const USERS_QK = "users";

export const useUsers = (
  keyword?: string,
  sortBy?: string,
  order?: string,
  page?: number,
  perPage?: string
) =>
  useQuery(
    [USERS_QK, keyword, sortBy, order, page, perPage],
    () => UsersService.get(keyword, sortBy, order, page, perPage),
    { keepPreviousData: true }
  );
export const useUsersUpdateStatus = (
  keyword?: string,
  sortBy?: string,
  order?: string,
  page?: number,
  perPage?: string
) =>
  useMutation(
    [USERS_QK, keyword, sortBy, order, page, perPage],
    (data: UserUpdateStatusInterface) => UsersService.updateStatus(data),
    {
      onSuccess: (updatedData, variables) => {
        queryClient.setQueryData(
          [USERS_QK, keyword, sortBy, order, page, perPage],
          (oldData: any) => {
            const links: any = oldData.data.links;
            const meta: any = oldData.data.meta;
            const users: Array<UserInterface> = oldData.data.data;
            const updatedUser: UserInterface = updatedData.data;

            return {
              ...oldData,
              data: {
                links,
                meta,
                data: users.map((user: UserInterface) =>
                  user.uuid === variables.uuid ? { ...user, status: updatedUser.status } : user
                ),
              },
            };
          }
        );
      },
    }
  );
