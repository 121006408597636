import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ResourceFileUploader } from "components/resources/resource-file-uploader";
import { ResourceFilesRow } from "components/resources/resource-files-row";
import { ResourceFileInterface } from "interfaces/resource";
import {
  useResource,
  useResourceFiles,
  useResourceFilesUpdateStatusByType,
  useResourceRemoveFilesByType,
} from "queries/resources";
import { ReactComponent as DisableStatusIcon } from "assets/icons/status-disabled-icon.svg";
import { ReactComponent as EnableStatusIcon } from "assets/icons/status-enabled-icon.svg";
import ResourcesService from "services/resources";
import useDebounce from "hooks/useDebounce";
import config from "utils/config";
import { getResoureFileTypeName } from "utils/helpers";

export const Resource = () => {
  const { uuid } = useParams();
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("");
  const debouncedSearch = useDebounce<string>(search, config.debounce.search);
  const { data, isLoading } = useResource(uuid);
  const { data: resourceFiles, isLoading: isLoadingResourceFiles } = useResourceFiles(
    uuid,
    debouncedSearch,
    filterType
  );
  const { mutate: removeFilesByType } = useResourceRemoveFilesByType(uuid);
  const { mutate: updateStatusByType } = useResourceFilesUpdateStatusByType(
    uuid,
    debouncedSearch,
    filterType
  );

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleTypeFilter = (e: any) => {
    setFilterType(e.target.value);
  };

  const handleToggleStatusByType = (type: number, active: boolean) => {
    updateStatusByType({ uuid: uuid || "", disabled: active ? 1 : 0, type });
  };

  const handleDownloadFilesByType = async (uuid: string, type: number) => {
    try {
      const { data, headers } = await ResourcesService.downloadFilesByType(uuid, type);

      if (type === 5 && data.url) {
        window.location.href = data.url;
        return;
      }

      const filename = headers["content-disposition"]
        .split(";")
        .find((n: string) => n.includes("filename="))
        .replace("filename=", "")
        .trim();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(data);
      link.setAttribute("download", filename);
      link.click();
      link.remove();
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast(`No ${getResoureFileTypeName(type)} files found`, {
          toastId: "download-resource-files-by-type-failed-not-found",
          type: "warning",
        });
      } else {
        toast("Something went from while requesting files from server", {
          toastId: "download-resource-files-by-type-failed",
          type: "error",
        });
      }
    }
  };

  const renderResourceFilesRow =
    resourceFiles?.data && resourceFiles?.data.length ? (
      resourceFiles?.data.map((resourceFile: ResourceFileInterface, index: number) => (
        <ResourceFilesRow
          key={resourceFile.uuid}
          index={index + 1}
          {...resourceFile}
          resourceUUID={uuid || ""}
          filterKeyword={search}
          filterType={filterType}
        />
      ))
    ) : (
      <tr>
        <td colSpan={5} className="text-center">
          <span className="text-white/50">No resource files found</span>
        </td>
      </tr>
    );

  return (
    <div className="flex flex-col mx-auto px-4 pt-4 items-center max-w-[2140px] h-full">
      <h1 className="text-3xl text-center my-8">
        {isLoading ? (
          <span className="block w-64 h-[36px] bg-white/5 rounded-md animate-pulse"></span>
        ) : (
          data?.data?.name
        )}
        {!isLoading && data === undefined && <span className="block w-64 h-[36px]"></span>}
      </h1>
      <div className="px-4 h-10 flex items-center flex-shrink-0 bg-white/5 rounded-md">
        <span>Download:</span>
        {[
          {
            label: "Case",
            params: [uuid, 5] as [string, number],
          },
          {
            label: getResoureFileTypeName(2),
            params: [uuid, 2] as [string, number],
          },
          {
            label: getResoureFileTypeName(3),
            params: [uuid, 3] as [string, number],
          },
          {
            label: getResoureFileTypeName(4),
            params: [uuid, 4] as [string, number],
          },
          {
            label: getResoureFileTypeName(6),
            params: [uuid, 6] as [string, number],
          },
          {
            label: getResoureFileTypeName(7),
            params: [uuid, 7] as [string, number],
          },
        ].map((btn) => (
          <div
            key={btn.label}
            className="flex items-center hover:cursor-pointer px-2 rounded-full bg-white/5 hover:bg-blue-500 transition-all ml-2"
          >
            <span
              tabIndex={0}
              className="px-2 font-bold outline-blue-500"
              onClick={() => handleDownloadFilesByType(...btn.params)}
              title={`Download ${btn.label} files`}
            >
              {btn.label}
            </span>
          </div>
        ))}
      </div>
      <div className="flex flex-grow w-full justify-evenly">
        <ResourceFileUploader uuid={uuid} />
        <ResourceFileUploader uuid={uuid} type="7" />
      </div>
      <div className="relative w-full flex justify-centerz-50 flex-col">
        <div className="flex-grow flex">
          <div className="relative flex-grow">
            <input
              className="w-full h-10 px-4 rounded-md bg-white/5 hover:bg-white/10 focus:bg-white/10 placeholder:text-white/30 outline-none transition-all"
              placeholder="Search"
              onChange={handleSearch}
              value={search}
            />
            {isLoadingResourceFiles && (
              <span className="absolute right-4 top-4 w-2 h-2 ring-1 ring-blue-500 rounded-full animate-ping z-10" />
            )}
          </div>
          <select
            className="ml-2 bg-white/5 px-3 h-10 rounded-md outline-blue-500 hidden md:block"
            onChange={handleTypeFilter}
            defaultValue=""
          >
            <option value="" defaultChecked>
              All
            </option>
            <option value="2">{getResoureFileTypeName(2)}</option>
            <option value="3">{getResoureFileTypeName(3)}</option>
            <option value="4">{getResoureFileTypeName(4)}</option>
            <option value="6">{getResoureFileTypeName(6)}</option>
            <option value="7">{getResoureFileTypeName(7)}</option>
          </select>
        </div>
        <div className="flex items-center">
          <div className="mr-2 my-2 px-4 h-10 flex flex-grow justify-center items-center bg-white/5 rounded-md">
            <span>Delete all:</span>
            {[
              {
                label: getResoureFileTypeName(2),
                callback: () => removeFilesByType({ uuid: uuid || "", type: 2 }),
              },
              {
                label: getResoureFileTypeName(3),
                callback: () => removeFilesByType({ uuid: uuid || "", type: 3 }),
              },
              {
                label: getResoureFileTypeName(4),
                callback: () => removeFilesByType({ uuid: uuid || "", type: 4 }),
              },
              {
                label: getResoureFileTypeName(6),
                callback: () => removeFilesByType({ uuid: uuid || "", type: 6 }),
              },
              {
                label: getResoureFileTypeName(7),
                callback: () => removeFilesByType({ uuid: uuid || "", type: 7 }),
              },
            ].map((btn) => (
              <span
                tabIndex={0}
                key={btn.label}
                onClick={() => btn.callback()}
                className="font-bold hover:cursor-pointer px-2 rounded-full border-2 border-white/10 outline-blue-500 hover:border-blue-500 transition-all ml-2"
              >
                {btn.label}
              </span>
            ))}
          </div>
          <div className=" px-4 h-10 flex items-center justify-center bg-white/5 rounded-md">
            <span>Status:</span>
            {[
              {
                label: getResoureFileTypeName(2),
                disableParams: [2, false] as [number, boolean],
                enableParams: [2, true] as [number, boolean],
              },
              {
                label: getResoureFileTypeName(3),
                disableParams: [3, false] as [number, boolean],
                enableParams: [3, true] as [number, boolean],
              },
              {
                label: getResoureFileTypeName(4),
                disableParams: [4, false] as [number, boolean],
                enableParams: [4, true] as [number, boolean],
              },
              {
                label: getResoureFileTypeName(6),
                disableParams: [6, false] as [number, boolean],
                enableParams: [6, true] as [number, boolean],
              },
              {
                label: getResoureFileTypeName(7),
                disableParams: [7, false] as [number, boolean],
                enableParams: [7, true] as [number, boolean],
              },
            ].map((btn) => (
              <div
                key={btn.label}
                className="flex items-center hover:cursor-pointer px-2 rounded-full bg-white/5 transition-all ml-2"
              >
                <DisableStatusIcon
                  tabIndex={0}
                  className="fill-white/50 hover:fill-orange-500 transition-all outline-blue-500 rounded-full"
                  onClick={() => handleToggleStatusByType(...btn.enableParams)}
                  title={`Hide ${btn.label} files`}
                />
                <span className="px-2 font-bold">{btn.label}</span>
                <EnableStatusIcon
                  tabIndex={0}
                  className="fill-white/50 hover:fill-blue-500 transition-all outline-blue-500 rounded-full"
                  onClick={() => handleToggleStatusByType(...btn.disableParams)}
                  title={`Make ${btn.label} files visible`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ scrollbarGutter: "stable" }} className="overflow-y-auto w-full flex-grow pb-4">
        <table className="table-fixed self-stretch w-full">
          <thead className="text-left sticky w-full top-0 z-10 bg-gradient-to-b from-[#121212]">
            <tr>
              <th className="pl-2 py-3 text-xs sm:text-base w-1/2">Name</th>
              <th className="py-3 text-xs sm:text-base w-1/2">UUID</th>
              <th className="py-3 text-xs sm:text-base w-[80px]">Type</th>
              <th className="py-3 text-xs sm:text-base w-[80px]">Status</th>
              <th className="pr-2 py-3 text-xs sm:text-base w-[36px]"></th>
            </tr>
          </thead>
          <tbody>
            {isLoadingResourceFiles ? (
              <>
                <tr className="bg-transparent transition-all">
                  <td className="pl-2 py-2 w-1/3">
                    <span className="block w-1/2 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2 w-1/2">
                    <span className="block w-1/2 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2 w-1/3">
                    <span className="block w-10 h-6 bg-white/5 rounded-md animate-pulse"></span>
                  </td>
                  <td className="py-2">
                    <span className="block w-10 h-6 bg-white/5 rounded-full animate-pulse"></span>
                  </td>
                  <td className="py-2 pr-2"></td>
                </tr>
              </>
            ) : (
              renderResourceFilesRow
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
